.centre {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.font_playfair_72 {
  font: normal normal normal 72px/1.25em 'Playfair Display', serif;
  letter-spacing: 0em;
}

.font_playfair_60 {
  font: normal normal normal 60px/1.30em 'Playfair Display', serif;
  letter-spacing: 0em;
}

.font_playfair_40 {
  font: normal normal normal 40px/1.35em 'Playfair Display', serif;
  letter-spacing: 0em;
}

.font_playfair_22 {
  font: normal normal normal 22px/1.41em 'Playfair Display', serif;  
  letter-spacing: 0em;
}

.font_raleway_15 {
  font: normal normal normal 15px/1.875em raleway, sans-serif;  
  letter-spacing: 0em;
}

.font_raleway_14 {
  font: normal normal normal 14px/1.79em 'Raleway', sans-serif;  
  letter-spacing: 0em;
}

.font_didot_18 {
  font: normal normal normal 18px/1.75em 'Didot-W01-Italic', serif;  
  letter-spacing: 0em;
}

.font_didot_20 {
  font: normal normal normal 20px/1.67em 'Didot-W01-Italic', serif;  
  letter-spacing: 0em;
}

h1 {
  font: normal normal normal 72px/1.25em 'Playfair Display', serif;
  letter-spacing: 0em;
  font-size: 72px !important;  
  margin: 62px auto 10px !important;
}

h2 {
  font: normal normal normal 40px/1.35em 'Playfair Display', serif;
  letter-spacing: 0em;
  font-size: 40px !important;
  text-align: center;
  margin: 62px auto 10px !important;
}

h3 {
  font: normal normal normal 22px/1.41em 'playfair display', serif;
  letter-spacing: 0em;
  font-size: 22px !important;
  text-align: center;
  margin: 62px auto 10px !important;
}

h4 {
  font: normal normal normal 18px/1.75em 'Didot-W01-Italic', serif;
  letter-spacing: 0em;
  font-size: 18px !important;
  text-align: center;
}

p {
  font: normal normal normal 15px/1.875em raleway, sans-serif;
  letter-spacing: 0em;
  width: 780px;
  margin: auto;
  text-align: center;
  color: black;
}

@media only screen and (max-width: 800px) {
  p {
    width: 550px;
  }
}

@media only screen and (max-width: 600px) {
  p {
    width: 350px;
  }
}

.white {
  color: white;
}

.black {
  color: black;
}

@font-face {
  font-display: block;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'),
    local('PlayfairDisplay-Regular'),
    url('PlayfairDisplay-Regular.ttf');
}

@font-face {
  font-display: block;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local('Raleway Regular'),
    local('Raleway-Regular'),
    url('Raleway-Regular.ttf');
}

@font-face {
  font-display: block;
  font-family: 'Didot-W01-Italic';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: 
    local('DidotLTW05-Italic'),
    url('DidotLTW05-Italic.woff2');
}

.pink {
  background-color: pink;
}

.debugborder {
  border: 2px;
  border-style: dashed;
}