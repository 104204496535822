#h1 {
    font: normal normal normal 60px/1.30em 'Playfair Display', serif !important;
    text-align: center;    
}

#intro {
    max-width: 700px;
    text-align: center;    
    margin: 30px auto 50px;
}

.box {
    height: 273px;
    width: 273px;    
    margin: auto;    
    position: relative;
    cursor: pointer;
}

.image {
    object-fit: cover;
    object-position: 50% 50%;
    height: 273px;
    width: 273px;
}

.label {
    font: normal normal normal 28px/1.375em 'playfair display', serif;
    text-shadow: 2px 2px black;
    color: white;
    position: absolute;
    top: 50%;    
    width: 100%;
    text-align: center;
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}


.hide {    
    opacity: 0%;
}

.hide:hover {
    opacity: 100%;    
    transition: opacity .4s ease;
}

.myDIV:hover + .hide {
    opacity: 100%;
    transition: opacity .4s ease;
}

.container {
    display: flex;
    border: 1px;    
    width: 960px;
    margin: auto;
    flex-wrap: wrap;
}

.item {    
    border: solid;
    border-width: 1px;    
    margin: 20px;
}

/* md */
@media only screen and (max-width: 992px) {
    .container {
        width: 640px;
    }
}

/* sm */
@media only screen and (max-width: 768px) {
    .container {
        width: 320px;
    }
}

/* xs */
@media only screen and (max-width: 576px) {
    
}

