.name {
    font: normal normal normal 60px/1.30em 'Playfair Display', serif;
    margin: 30px auto 10px;
}

.subtitle {
    font: normal normal normal 24px/1.4em 'playfair display', serif;
    text-align: left !important;
    margin: 30px auto 10px !important;
}

.subtext {
    font: normal normal normal 20px/1.75em 'Didot-W01-Italic', serif;
    text-align: left;    
}

.previous {        
    text-align: left;
}

.next {    
    text-align: right;
}

.arrow {
    font: normal normal normal 24px/1.4em 'playfair display', serif;
    font-weight: bold;
}

.button {
    font: normal normal normal 24px/1.4em 'playfair display', serif;
    border: 0px;
    background-color: white;
}

.image {
    object-fit: cover;
    object-position: 50% 50%;
    height: 400px;
    width: 100%;
}

.para {    
    text-align: left;    
}