.figurefirst {
    border-style: solid;
    border-width: 1px;
    border-color: black;
}

.figure {
    border-style: solid;
    border-width: 1px;
    margin-left: -1px; /* so we don't get a double border between pics */
    border-color: black;
}

.caption {
    font: normal normal normal 12px/1.875em raleway, sans-serif;
    color: black;
}