#myleft {    
    height: 660px;
    position: relative;        
}

#titleabout {    
    width: 400px;    
    position: absolute;
    top: 50%;    
}

#titleimage {
    object-fit: cover;
    object-position: 50% 50%;
    height: 630px;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    #myleft {
        height: 400px;
    }

    #titleabout {
        width: 350px;
    }

    #titleimage {
        height: 400px;
    }
}