.form {
    background-color: #F7F7F7;
    /* height: 531px;*/
}

p {
    margin: 22px auto;
    width: 100% !important;
}

.inputdiv {
    height: 67px;
    margin: 8px auto;
}

.textareadiv {
    height: 133px;
    margin: 8px auto;
}

#spacer {
    padding: 30px;
}

#name {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==");
}


label {
    font: normal normal normal 15px/1.875em raleway, sans-serif;
}

.input {
    font: normal normal normal 15px/1.875em raleway, sans-serif;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 98% 50%;
    cursor: pointer;
    min-height: 50px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid black;
}

.textarea {
    font: normal normal normal 15px/1.875em raleway, sans-serif;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 98% 50%;
    cursor: pointer;
    min-height: 116px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid black;
    background-color: inherit;
}

.input:invalid {
    border-style: solid;
    border-width: 1px;
    background-color: rgba(255, 64, 64, 0.1);
    border-color: rgba(255, 64, 64, 1);
}

.input:invalid:required {
    background-image: linear-gradient(to right, pink, lightgreen);
}

.input:valid {
    background-color: inherit;
}

.button {
    width: 100%;
    font: normal normal normal 18px/1.75em 'Didot-W01-Italic', serif;
    background-color: black;
    color: white;
    margin: 20px auto;
    padding: 5px;
}