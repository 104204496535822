#title {
    margin: 36px 0px 20px;
    text-align: center;    
}

#email {
    text-align: center;    
}

#copyright {
    margin: 43px 0px;
    text-align: center;    
}