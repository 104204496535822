.container {
    height: 660px;
    position: relative;
}

#lydiaphoto {
    object-fit: cover;
    object-position: 50% 50%;
    height: 660px;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .container {
        height: 400px;            
    }
    #lydiaphoto {        
        height: 400px;           
    }
}

#biotitle {    
    margin: 33px auto 10px;    
}

#getintouch {
    margin: 33px auto;
    text-align: center;
}

#iconimg {
    margin: 20px auto;
    display: block;
    width: 120px;    
}