.color-nav {
    background-color: #F7F7F7;
}

.nav-link {    
    font: normal normal normal 14px/1.79em 'Raleway', sans-serif;
}

@media only screen and (max-width: 768px) {
    .nav-link {        
        margin: 10px auto;
    }
}